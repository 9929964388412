/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetPendingModifyRequestsRL = /* GraphQL */ `
  query GetPendingModifyRequestsRL {
    GetPendingModifyRequestsRL
  }
`;
export const ListUsers = /* GraphQL */ `
  query ListUsers {
    ListUsers
  }
`;
export const ListIssuersSubscriptionFilter = /* GraphQL */ `
  query ListIssuersSubscriptionFilter(
    $input: listIssuersSubscriptionFilterInput
  ) {
    ListIssuersSubscriptionFilter(input: $input)
  }
`;
export const ListIssuers = /* GraphQL */ `
  query ListIssuers($input: ListIssuersInput) {
    ListIssuers(input: $input)
  }
`;
export const ListPendingKYCUsers = /* GraphQL */ `
  query ListPendingKYCUsers {
    ListPendingKYCUsers
  }
`;
export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: UserEmailIDInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const ListCountries = /* GraphQL */ `
  query ListCountries($input: ListCountriesInput) {
    ListCountries(input: $input)
  }
`;
export const ListTemplates = /* GraphQL */ `
  query ListTemplates {
    ListTemplates
  }
`;
export const ListCategories = /* GraphQL */ `
  query ListCategories {
    ListCategories
  }
`;
export const ListAllCredentialsOfTemplate = /* GraphQL */ `
  query ListAllCredentialsOfTemplate(
    $input: ListAllCredentialsOfTemplateInput
  ) {
    ListAllCredentialsOfTemplate(input: $input)
  }
`;
export const ListAllCredentials = /* GraphQL */ `
  query ListAllCredentials($input: ListAllCredentialsInput) {
    ListAllCredentials(input: $input)
  }
`;
export const GetDefaultConfiguration = /* GraphQL */ `
  query GetDefaultConfiguration {
    GetDefaultConfiguration
  }
`;
export const ListMoficationRequests = /* GraphQL */ `
  query ListMoficationRequests {
    ListMoficationRequests
  }
`;
export const ListIssuerTypes = /* GraphQL */ `
  query ListIssuerTypes {
    ListIssuerTypes
  }
`;
export const ListPartners = /* GraphQL */ `
  query ListPartners {
    ListPartners
  }
`;
export const ListConfigurationFee = /* GraphQL */ `
  query ListConfigurationFee {
    ListConfigurationFee
  }
`;
export const GetExpiringCustomers = /* GraphQL */ `
  query GetExpiringCustomers {
    GetExpiringCustomers
  }
`;
export const RLGetCurrentUserDetails = /* GraphQL */ `
  query RLGetCurrentUserDetails($input: RLGetCurrentUserDetailsInput) {
    RLGetCurrentUserDetails(input: $input)
  }
`;
export const GetS3AccountLogoURL = /* GraphQL */ `
  query GetS3AccountLogoURL($input: GetS3AccountLogoURLInput!) {
    GetS3AccountLogoURL(input: $input)
  }
`;
